(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/deposit-restriction/assets/javascripts/react/constants/deposit-amounts.js') >= 0) return;  svs.modules.push('/components/accountservices/deposit-restriction/assets/javascripts/react/constants/deposit-amounts.js');
"use strict";


const underTwentyFive = [{
  value: 0,
  label: 'Jag vill inte sätta in mer idag',
  minWidth: '100%'
}, {
  value: 1,
  label: '1',
  minWidth: '50%'
}, {
  value: 2,
  label: '2',
  minWidth: '50%'
}];
const overTwentyFive = [{
  value: 0,
  label: 'Jag vill inte sätta in mer idag',
  minWidth: '100%'
}, {
  value: 1,
  label: '1',
  minWidth: '33.33%'
}, {
  value: 2,
  label: '2',
  minWidth: '33.33%'
}, {
  value: 3,
  label: '3',
  minWidth: '33.33%'
}];
const AGE_GROUPS = {
  underTwentyFive,
  overTwentyFive
};
svs.accountservices.deposit_restriction.AgeGroups = AGE_GROUPS;

 })(window);